<template>
  <div class="map-div">
    <div class="map-title">
      <Select v-model:value="formState.address_code" @changeAddress="changeAddress" :returnIDFormat="true" class="map-iput" />
      <a-input v-model:value="formState.address_detail" class="map-iput" />
    </div>
    <div id="container"></div>
    <div class="search-box">
      <a-input class="search-input" v-model:value="keyword" placeholder="请输入关键字" />
      <a-button type="primary" @click="searchAddress">查找地址</a-button>
    </div>
    <div id="panel"></div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { shallowRef } from "@vue/reactivity";
import { Select } from "@/components/Map";
import { returnAddressByCityCode } from "./libs/utils.js";
export default defineComponent({
  components: {
    Select,
  },
  name: "mapcomtaint",
  emits: ["update:modelValue"],
  setup(props, context) {
    // 下拉选择省市区
    const formState = reactive({
      lng_lat: [104.065692, 30.660353],
      address_code: [],
      address: [],
      address_detail: "",
    });

    const keyword = ref("");

    context.emit("update:modelValue", formState);

    const map = shallowRef(null);
    const mapSearch = shallowRef(null);
    // const marker = shallowRef(null);
    window._AMapSecurityConfig = {
      securityJsCode: "97d0031d5081f74efeaa6a549f58bd71",
    };
    const ininMap = () => {
      AMapLoader.load({
        key: "5e3df5ea7214e9723e75267ac65b14eb", //设置您的key
        version: "2.0",
        plugins: [
          "AMap.ToolBar",
          "AMap.Driving",
          "AMap.Geolocation",
          "AMap.Geocoder",
          "AMap.PlaceSearch",
        ],
        AMapUI: {
          version: "1.1",
          plugins: ["overlay/SimpleMarker", "misc/PositionPicker"],
        },
        Loca: {
          version: "2.0.0",
        },
      })
        .then((AMap) => {
          // 初始化经纬度-formState.lng_lat

          // 初始化地图
          map.value = new AMap.Map("container", {
            viewMode: "3D",
            zoom: 16,
            zooms: [2, 22],
            center: formState.lng_lat,
          });

          // 绘制标记-添加中心标记
          let marker = new AMap.Marker({
            position: formState.lng_lat,
          });
          map.value.add(marker);

          // 绘制提示信息
          let infoWindow = new AMap.InfoWindow({
            content: "",
            offset: { x: 0, y: -30 },
          });

          // 绑定点击事件
          map.value.on("click", showInfoClick);

          // 地图上点击操作
          function showInfoClick(e) {
            // console.log(e);
            let lng = e.lnglat.getLng();
            let lat = e.lnglat.getLat();
            marker.setPosition([lng, lat]);
            // 设置选择的经纬度
            map.value.setZoomAndCenter(16, [lng, lat]);
            // console.log("您在 [ " + lng + "," + lat + " ] 的位置单击了地图！");
            // 通过经纬度查找地址
            getAddress(lng, lat);
          }

          function getAddress(lng, lat) {
            let geocoder = new AMap.Geocoder({
              radius: 500, //范围，默认：500
            });

            geocoder.getAddress([lng, lat], function (status, result) {
              // console.log(status);
              // console.log(result);
              if (status == "complete") {
                infoWindow.setContent(
                  "<p>" + result.regeocode.formattedAddress + "</p>"
                );
                infoWindow.open(map.value, [lng, lat]);
                formState.address_detail =
                  result.regeocode.addressComponent.township +
                  result.regeocode.addressComponent.street +
                  result.regeocode.addressComponent.streetNumber;

                // 绑定省市区
                let city_code = result.regeocode.addressComponent.adcode;
                let region = returnAddressByCityCode(city_code);

                formState.address_code = region.region_code;
                formState.address = region.region_full;
              }
            });
          }

          mapSearch.value = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            city: "全国", // 兴趣点城市
            citylimit: true, //是否强制限制在设置的城市内搜索
            map: map.value, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          mapSearch.value.on("selectChanged", function (e) {
            // console.log(e.selected.data.location);
            let lng = e.selected.data.location.lng;
            let lat = e.selected.data.location.lat;

            marker.setPosition([lng, lat]);
            // 设置选择的经纬度
            map.value.setZoomAndCenter(16, [lng, lat]);
            // console.log("您在 [ " + lng + "," + lat + " ] 的位置单击了地图！");
            // 通过经纬度查找地址
            getAddress(lng, lat);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const searchAddress = () => {
      // console.log(keyword.value);
      mapSearch.value.search(keyword.value);
    };

    const changeAddress = (data) => {
      // console.log(data);
      if (data != undefined) {
        formState.lng_lat =
          data.length > 2
            ? [data[2]["location"]["lng"], data[2]["location"]["lat"]]
            : [data[1]["location"]["lng"], data[1]["location"]["lat"]];
        map.value.setZoomAndCenter(16, formState.lng_lat);
      } else {
        console.log("空的");
      }
      formState.address_detail = "";
    };

    onMounted(() => {
      //DOM初始化完成进行地图初始化
      ininMap();
    });
    return {
      map,
      formState,
      keyword,
      searchAddress,
      changeAddress,
    };
  },
});
</script>
<style scope lang="less">
.map-div {
  height: 400px;
  width: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}
#container {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.search-box {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 280px;
  .search-input {
    width: 192px;
  }
}
#panel {
  position: absolute;
  background-color: white;
  max-height: 80%;
  overflow-y: auto;
  top: 60px;
  right: 10px;
  width: 280px;
}
.map-title {
  position: absolute;
  z-index: 1;
  width: 550px;
  margin: 20px;
  .map-iput {
    width: 220px;
  }
}
</style>