import libMap3 from "./lbs.map3.json";
export const returnAddressByCityCode = (areaCode = 0) => {
    let region_code = [];
    let region_full = [];
    if (libMap3[areaCode] !== "undefined") {
        // 获取得到区（只有两级）或者市（三级） 数据
        let area = libMap3[areaCode];
        if (libMap3[area.pid] !== "undefined" && parseInt(area.pid) > 0) {
            let city = libMap3[area.pid];
            if (libMap3[city.pid] !== "undefined" && parseInt(city.pid) > 0) {
                let province = libMap3[city.pid];
                region_code.push(province["id"]);
                region_full.push(province);
            }
            region_code.push(city["id"]);
            region_full.push(city);
        }
        region_code.push(area["id"]);
        region_full.push(area);
    }
    return { 'region_code': region_code, 'region_full': region_full };
}