<template>
  <div class="main-body">

    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="地图选择">
        <MapSelect v-model="formState.map" />
      </a-form-item>
      <a-form-item label="网站标题">
        <a-input v-model:value="formState.title" placeholder="请输入网站标题" />
      </a-form-item>
      <a-form-item label="地区单选">
        <Select v-model="formState.address_one" :returnIDFormat="false" />
      </a-form-item>
      <a-form-item label="地区多选">
        <MultiSelect v-model="formState.address_mutli" :returnIDFormat="false" />
      </a-form-item>
      <a-form-item label="测试">
        <a-switch v-model:checked="formState.checked" />
      </a-form-item>
      <a-form-item label="附件上传">
        <UpFile v-model="formState.file_url" />
      </a-form-item>
      <a-form-item label="视频地址">
        <CsVideo v-model="formState.video_url" />
      </a-form-item>

      <a-form-item label="音频地址">
        <CsAudio v-model:audio="formState.audio_url" /><span>{{formState.audio_url}}</span>
      </a-form-item>

      <a-form-item label="多图表单">
        <div class="muti-image-item" v-for="(item, index) in formState.image_list" style="margin-bottom: 20px" :key="index">
          <a-row>
            <a-col :span="4">
              <CsImage v-model:imgOne="item.imgUrl" />
            </a-col>
            <a-col :span="20">
              <a-input style="margin-bottom: 6px" v-model:value="item.imgTitle" placeholder="请输入标题" />
              <a-input v-model:value="item.imgLink" placeholder="请输入跳转地址" />
            </a-col>
          </a-row>
          <CloseCircleOutlined @click="removeImageItem(item)" class="delete-image-item" />
        </div>
        <a-button type="dashed" block @click="addImageItem">
          <PlusOutlined />
          添加图片
        </a-button>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
    <a-row>
      <a-col :span="6">
        <a-card title="WebEditor">
          <div class="card-box">
            <a-button type="primary">
              <WebEditor :data="web_editor_data" :txt="'打开WebEditor'" />
            </a-button>

            <div class="demo-desc">
              PC网页编辑器，装修页面，移动端请使用PhoneEditor
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="链接地址库">
          <div class="card-box">
            <CsLinks @update="updateLink" />
            <div class="demo-desc">选择链接地址回调（移动端）</div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="图片库">
          <div class="card-box">
            <div style="display: flex"></div>

            <div class="demo-desc">
              width:设置宽度;height:设置高度;tips:设置提示语；style:设置显示样式（default,button,dotted）
            </div>
            <p></p>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="客服">
          <div class="card-box">
            <a-button @click="openKeFu()">客服系统</a-button>
            <a-modal v-model:visible="chat_visible" width="1200px" title="客服系统">
              <Chat msg="这里是客服工作台" />
            </a-modal>
            <div class="demo-desc">打开客服聊天系统</div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div style="margin: 20px">
      <CsImage :multiple="true" v-model:imgMulti="imgUrlArr" />
    </div>

    <div style="margin: 20px">
      <CsImage v-model:imgOne="imgUrl" />
    </div>
    <div style="margin: 20px">
      <CsImage v-model:imgOne="imgUrl" :style="'button'" />
    </div>
    <div style="margin: 20px">
      <a-button type="primary" @click="getInfo">打印图片信息</a-button>
      <!-- <mavon-editor :subfield="false" /> -->
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { WebEditor } from "@/components/Page";
import CsImage from "@/components/UpImage";
import CsVideo from "@/components/UpVideo";
import CsAudio from "@/components/UpAudio";
import UpFile from "@/components/UpFile";
import CsLinks from "@/components/Links";
import Chat from "@/components/Chat";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

import { Select, MultiSelect, MapSelect } from "@/components/Map";

export default defineComponent({
  components: {
    WebEditor,
    CsImage,
    CsLinks,
    Chat,
    CsVideo,
    CsAudio,
    UpFile,
    PlusOutlined,
    CloseCircleOutlined,
    Select,
    MultiSelect,
    MapSelect,
  },
  setup() {
    const web_editor_data = ref();

    const chat_visible = ref(false);

    const openKeFu = () => {
      chat_visible.value = true;
    };

    // 链接选择回调
    const updateLink = (val) => {
      console.log(val);
    };

    const imgUrl = ref();
    const imgUrlArr = ref();

    const getInfo = () => {
      console.log(imgUrlArr.value);
      console.log(imgUrl.value);
      console.log("----------");
      console.log(formState);
    };

    const openWebEditor = () => {};

    const formState = reactive({
      title: "",
      address_one: [],
      address_mutli: [],
      map: [],
      checked: true,
      video_url: "",
      audio_url: "",
      file_url: "",
      image_list: [
        {
          imgUrl:
            "http://tp6.crate.com/public/uploads/image/20220315/0a324c650cbd0a94916347a77e406d46.webp",
          imgTitle: "测试标题1",
          imgLink: "https://www.baidu.com/",
        },
        {
          imgUrl:
            "http://tp6.crate.com/public/uploads/image/20220315/0a324c650cbd0a94916347a77e406d46.webp",
          imgTitle: "测试标题2",
          imgLink: "https://www.baidu.com/",
        },
      ],
    });

    const addImageItem = () => {
      formState.image_list.push({
        imgUrl: "",
        imgTitle: "",
        imgLink: "",
      });
    };

    const removeImageItem = (item) => {
      if (formState.image_list.length > 2) {
        let index = formState.image_list.indexOf(item);

        if (index !== -1) {
          formState.image_list.splice(index, 1);
        }
        message.success("删除成功");
      } else {
        message.warning("至少保留2项！");
      }
    };

    const onSubmit = () => {};

    return {
      openWebEditor,
      web_editor_data,
      chat_visible,
      openKeFu,
      updateLink,
      imgUrl,
      imgUrlArr,
      getInfo,
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 12,
      },
      formState,
      onSubmit,
      addImageItem,
      removeImageItem,
    };
  },
});
</script>
<style scoped lang="less">
.demo-desc {
  margin-top: 30px;
}
.card-box {
  height: 160px;
}
.muti-image-item {
  position: relative;
  .delete-image-item {
    position: absolute;
    right: -20px;
    top: 0;
  }
}
</style>