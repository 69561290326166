<template>
  <div class="audio-module">
    <a-button type="primary" size="small" @click="openAudioModle">替换</a-button>
  </div>
  <a-modal v-model:visible="audio_visible" width="940px" :bodyStyle="{padding:0}" @ok="handleSubmit"
    @cancel="handleCancel" title="音频库">
    <div style="height: 550px">
      <a-row>
        <a-col :span="5">
          <div class="image-group">
            <a-tree v-if="treeData" class="draggable-tree" draggable block-node default-expand-all :tree-data="treeData"
              :fieldNames="replaceFields" @select="selectGroup" />
          </div>
        </a-col>
        <a-col :span="19">
          <div class="image-list">
            <div class="action-top">
              <a-row>
                <a-col :span="8">
                  <a-input-search placeholder="搜索文件名称" v-model:value="audioData.keyword" @search="onSearch" />
                </a-col>
                <a-col :span="16">
                  <div class="upload-action">
                    <span class="upload-desc">大小不能超过2M</span>
                    <a-upload name="iFile" :multiple="true" :showUploadList="false" :headers="headers"
                      :action="uploadAction" @change="handleChange">
                      <a-button>
                        <CloudUploadOutlined />
                        上传
                      </a-button>
                    </a-upload>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="audio-list-body">
              <div v-if="audioData.audio_list.length > 0">
                <a-spin :spinning="spinning">
                  <div class="audio-list-box">
                    <a-row type="flex">
                      <a-col :span="12" v-for="(item, index) in audioData.audio_list" :key="index">
                        <div :class="item == selectedItem ?'audio-item active' :'audio-item'"
                          @click="selectAudio(item)">
                          <a-row>
                            <a-col :span="14">
                              <p class="audio-title">{{ item.audioName }}</p>
                              <p>{{ item.create_time }}</p>
                            </a-col>
                            <a-col :span="10">
                              <div class="audio-image">
                                <div><i class="icon-audio"></i></div>
                                <div class="audio-time">04:10</div>
                              </div>
                            </a-col>
                          </a-row>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div class="audio-footer">
                    <div class="audio-pagination">
                      <a-pagination v-model:current="audioData.current_page" v-model:pageSize="audioData.per_page"
                        :total="audioData.total" show-less-items @change="onLoadAudioList" />
                    </div>
                  </div>
                </a-spin>
              </div>
              <div v-else>
                <a-empty />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
  import {
    defineComponent,
    ref,
    reactive,
    computed
  } from "vue";
  import {
    message
  } from "ant-design-vue";
  import {
    CloudUploadOutlined
  } from "@ant-design/icons-vue";
  import storage from "store";
  import * as Api from "./api";
  import "./style/audio.less";

  export default defineComponent({
    components: {
      CloudUploadOutlined,
    },
    name: "CsAudio",
    props: {
      audio: {
        type: String,
        default: "",
      },
    },
    setup(props, {
      emit
    }) {
      // 音频
      const audio = computed({
        get: () => props.audio,
        set: (value) => emit("update:audio", value),
      });

      // 上传弹框
      const audio_visible = ref(false);

      const openAudioModle = () => {
        getGroupList();
        onLoadAudioList();
        audio_visible.value = true;
      };

      // 已经被选中的索引
      const selectedItem = ref([]);

      const selectAudio = (item) => {
        selectedItem.value = item;
      };

      // 确定选择触发
      const handleSubmit = () => {
        if (selectedItem.value.length == 0) {
          message.error("请选择音频文件");
        } else {
          audio.value = selectedItem.value.audioUrl;
          audio_visible.value = false;
        }
      };

      const handleCancel = () => {};

      const uploadAudio = () => {};

      //上传文件
      const uploadAction = Api.upload;

      const handleChange = (info) => {
        if (info.file.status === "done") {
          if (info.file.response.status === 200) {
            onLoadAudioList();
            message.success(`${info.file.name} 上传成功`);
          } else {
            message.error(info.file.response.message);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败.`);
        }
      };

      // 打开图片库获取分组
      const treeData = ref();

      //获取分组数据

      const getGroupList = () => {
        // 获取图片库分组数据
        Api.group_list({
          type: 30
        }).then((res) => {
          treeData.value = res.data.list;
        });
      };

      const selectGroup = (selectKeys) => {
        if (selectKeys.length > 0) {
          audioData.group_id = parseInt(selectKeys[0]);
        } else {
          audioData.group_id = -1;
        }
        onLoadAudioList();
      };

      // 请求相册列表
      const audioData = reactive({
        keyword: "",
        audio_list: [],
        total: 0,
        current_page: 0,
        per_page: 0,
        group_id: -1,
      });

      const spinning = ref(true); //加载效果

      const onLoadAudioList = (page = 1) => {
        spinning.value = true;
        Api.audio({
          page: page,
          group_id: audioData.group_id,
          keyword: audioData.keyword,
        }).then((res) => {
          audioData.audio_list = res.data.data;
          audioData.total = res.data.total;
          audioData.current_page = res.data.current_page;
          audioData.per_page = res.data.per_page;

          spinning.value = false;
        });
      };

      // 搜索
      const onSearch = () => {
        onLoadAudioList();
      };

      const replaceFields = {
        children: "children",
        title: "name",
        key: "group_id",
        parent_id: "parent_id",
      };

      const headers = ref({
        "Access-Token": storage.get("ACCESS_TOKEN"),
      });

      return {
        uploadAudio,
        audio_visible,
        openAudioModle,
        handleSubmit,
        handleCancel,
        treeData,
        audioData,
        selectGroup,
        onSearch,
        spinning,
        onLoadAudioList,
        selectAudio,
        selectedItem,
        uploadAction,
        handleChange,
        replaceFields,
        headers,
      };
    },
  });
</script>