<template>
  <a-cascader v-model:value="selectValue" multiple max-tag-count="responsive" @change="selectChange" :fieldNames="fieldNames" :options="libMap" placeholder="Please select"></a-cascader>
</template>
<script>
import { defineComponent, ref } from "vue";
import libMap from "./libs/lbs.map.json";

export default defineComponent({
  name: "MultiSelect",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    returnIDFormat: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const selectValue = ref(props.modelValue);
    const selectChange = (value, selectedOptions) => {
      if (props.returnIDFormat == true) {
        context.emit("update:modelValue", value);
      } else {
        context.emit("update:modelValue", selectedOptions);
      }
    };
    return {
      selectValue,
      selectChange,
      libMap,
      fieldNames: {
        label: "fullname",
        value: "id",
        children: "children",
      },
    };
  },
});
</script>