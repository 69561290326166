<template>
  <div class="chat-body">
    <a-row>
      <a-col :xl="6">
        <div class="chat-userlist">
          <div class="search-input-box">
            <a-input-search v-model:value="value" placeholder="搜索用户或者内容" @search="onSearch" />
          </div>
          <a-tabs centered>
            <a-tab-pane key="1" tab="当前会话">
              <div class="message-box">
                <a-list item-layout="horizontal" :data-source="userData">
                  <template #renderItem="{ item }">
                    <a-list-item class="user-item">
                      <template #actions>
                        <span>12:45</span>
                      </template>
                      <a-list-item-meta class="message-line-chat" description="您好，请我我的快递什么时候送呀！搞快送快递哟 等你哦">
                        <template #title>
                          <a href="#">{{ item.title }}</a>
                        </template>
                        <template #avatar>
                          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </template>
                </a-list>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="最近联系">2</a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
      <a-col :xl="12">
        <div class="chat-content">
          <div class="message-list" id="msgBox">
            <div v-for="(item, index) in msg_list_data" :key="index" class="msg-item">
              <div v-if="item.user_id === 1" class="msg-me">
                <div class="msg-user-title">
                  <span class="msg-username">{{ item.username }}</span>
                  <a-avatar class="msg-avatar" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                </div>
                <div class="msg-content-box">
                  <span class="msg-content-text">{{ item.content }}</span>
                </div>
              </div>
              <div v-else class="msg-other">
                <div class="msg-user-title">
                  <a-avatar class="msg-avatar" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  <span class="msg-username">{{ item.username }}</span>
                </div>
                <div class="msg-content-box">
                  <span class="msg-content-text">{{ item.content }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="message-send-box">
            <div class="message-action-icon">
              <SmileOutlined class="icon-item" />
              <PictureOutlined class="icon-item" />
              <PlayCircleOutlined class="icon-item" />
              <FormOutlined class="icon-item" />
            </div>
            <div class="message-input">
              <a-textarea class="message-input-text" v-model:value="formState.content" placeholder="发送给小飞侠，按Enter发送" />
              <a-button type="primary" size="small" class="send-button" @click="sendMessage()">发送</a-button>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xl="6">
        <div class="chat-workbench">
          <a-card title="用户画像" :bordered="false">
            <a-row>
              <a-col :span="12" v-for="(item, index) in 4" :key="index">
                来源：抖音
              </a-col>
            </a-row>
          </a-card>
          <div class="chat-user-order">
            <a-tabs>
              <a-tab-pane key="1" tab="全部">
                <p>Content of Tab Pane 1</p>
                <p>Content of Tab Pane 1</p>
                <p>Content of Tab Pane 1</p>
                <p>Content of Tab Pane 1</p>
                <p>Content of Tab Pane 1</p>
                <p>Content of Tab Pane 1</p>
              </a-tab-pane>
              <a-tab-pane key="2" tab="未完结">1111</a-tab-pane>
              <a-tab-pane key="3" tab="已完结">3333</a-tab-pane>
            </a-tabs>
          </div>
          <a-card title="快捷回复" :bordered="false">
            <p v-for="(item, index) in 4" :key="index">
              您好，您货品已发出，请耐性等待！
            </p>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import "./style/chat.less";
import {
  SmileOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  FormOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import chatData from "@/components/Chat/chat.json";

export default defineComponent({
  name: "Chat",
  props: {
    msg: String,
  },
  components: {
    SmileOutlined,
    PictureOutlined,
    PlayCircleOutlined,
    FormOutlined,
  },
  setup() {
    const formState = reactive({
      content: "",
    });

    const msg_list_data = ref(chatData);

    const sendMessage = () => {
      if (formState.content != "") {
        let send_data = {
          user_id: 1,
          user_name: "客服小美",
          content: formState.content,
        };
        msg_list_data.value.push(send_data);
        formState.content = "";
        scrolBottom();
      }
    };

    const scrolBottom = () => {
      const height = computed(() => {
        return document.getElementById("msgBox").scrollHeight;
      });
      document.getElementById("msgBox").scrollTop = height.value;
    };

    const onSearch = () => {};

    onMounted(() => {
      scrolBottom();
    });

    return {
      userData: ref([]),
      formState,
      msg_list_data,
      sendMessage,
      onSearch,
      value: ref(""),
    };
  },
});
</script>