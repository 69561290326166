<template>
  <div class="video-module">
    <a-input placeholder="请输入视频地址" v-model:value="videoUrl" @change="videoChange">
      <template #suffix>
        <a-button type="primary" size="small" @click="openVideoModle">替换</a-button>
      </template>
    </a-input>
    <video class="video-player" ref="videoPlayer" controls :src="videoUrl" :poster="poster"
      controlslist="nodownload"></video>
  </div>
  <a-modal v-model:visible="video_visible" width="1200px" :bodyStyle="{padding:0}" @ok="handleSubmit"
    @cancel="handleCancel" title="视频库">
    <div style="height: 550px">
      <a-row>
        <a-col :span="5">
          <div class="image-group">
            <a-tree v-if="treeData" class="draggable-tree" draggable block-node default-expand-all :tree-data="treeData"
              :fieldNames="replaceFields" @select="selectGroup" />
          </div>
        </a-col>
        <a-col :span="19">
          <div class="image-list">
            <div class="action-top">
              <a-row>
                <a-col :span="8">
                  <a-input-search placeholder="搜索文件名称" v-model:value="videoData.keyword" @search="onSearch" />
                </a-col>
                <a-col :span="16">
                  <div class="upload-action">
                    <span class="upload-desc">大小不能超过2M</span>
                    <a-upload name="iFile" :multiple="true" :showUploadList="false" :headers="headers"
                      :action="uploadAction" @change="handleChange">
                      <a-button>
                        <CloudUploadOutlined />
                        上传
                      </a-button>
                    </a-upload>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="video-list-body">
              <div v-if="videoData.video_list.length > 0">
                <a-spin :spinning="spinning">
                  <div class="video-list-box">
                    <a-row type="flex">
                      <a-col :span="8" v-for="(item, index) in videoData.video_list" :key="index">
                        <div class="video-item" @click="selectVideo(item)">
                          <p>{{ item.videoName }}</p>
                          <a-image class="video-item-player" :preview="false" :src="poster" />
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div class="video-footer">
                    <div class="video-pagination">
                      <a-pagination v-model:current="videoData.current_page" v-model:pageSize="videoData.per_page"
                        :total="videoData.total" show-less-items @change="onLoadVideoList" />
                    </div>
                  </div>
                </a-spin>
              </div>
              <div v-else>
                <a-empty />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
  import {
    defineComponent,
    ref,
    reactive
  } from "vue";
  import {
    message
  } from "ant-design-vue";
  import {
    CloudUploadOutlined
  } from "@ant-design/icons-vue";
  import storage from "store";
  import * as Api from "./api";
  import "./style/video.less";

  export default defineComponent({
    components: {
      CloudUploadOutlined,
    },
    name: "CsVideo",
    props: {
      modelValue: String,
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const poster = ref(require("./style/default_cover.png"));

      const videoUrl = ref(props.modelValue);

      const videoChange = () => {
        context.emit("update:modelValue");
      };

      // 上传弹框
      const video_visible = ref(false);

      const openVideoModle = () => {
        getGroupList();
        onLoadVideoList();
        video_visible.value = true;
      };

      // 确定选择触发
      const handleSubmit = () => {};

      const handleCancel = () => {};

      const uploadVideo = () => {};

      //上传文件
      const uploadAction = Api.upload;

      const handleChange = (info) => {
        if (info.file.status === "done") {
          if (info.file.response.status === 200) {
            onLoadVideoList();
            message.success(`${info.file.name} 上传成功`);
          } else {
            message.error(info.file.response.message);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败.`);
        }
      };

      // 打开图片库获取分组
      const treeData = ref();

      //获取分组数据

      const getGroupList = () => {
        // 获取图片库分组数据
        Api.group_list({
          type: 20
        }).then((res) => {
          treeData.value = res.data.list;
        });
      };

      const selectGroup = (selectKeys) => {
        if (selectKeys.length > 0) {
          videoData.group_id = parseInt(selectKeys[0]);
        } else {
          videoData.group_id = -1;
        }
        onLoadVideoList();
      };

      // 请求相册列表
      const videoData = reactive({
        keyword: "",
        video_list: [],
        total: 0,
        current_page: 0,
        per_page: 0,
        group_id: -1,
      });

      const spinning = ref(true); //加载效果

      // 已经被选中的索引
      const selectedIndexs = ref([]);

      const onLoadVideoList = (page = 1) => {
        spinning.value = true;
        Api.video({
          page: page,
          group_id: videoData.group_id,
          keyword: videoData.keyword,
        }).then((res) => {
          videoData.video_list = res.data.data;
          videoData.total = res.data.total;
          videoData.current_page = res.data.current_page;
          videoData.per_page = res.data.per_page;

          spinning.value = false;
        });

        selectedIndexs.value = [];
      };

      // 搜索
      const onSearch = () => {
        onLoadVideoList();
      };

      const selectVideo = (item) => {
        console.log(item);
        videoUrl.value = item.videoUrl;
        video_visible.value = false;
         context.emit("update:modelValue",videoUrl);
      };

      const replaceFields = {
        children: "children",
        title: "name",
        key: "group_id",
        parent_id: "parent_id",
      };

      const headers = ref({
        "Access-Token": storage.get("ACCESS_TOKEN"),
      });

      return {
        poster,
        videoChange,
        videoUrl,
        uploadVideo,
        video_visible,
        openVideoModle,
        handleSubmit,
        handleCancel,
        treeData,
        videoData,
        selectGroup,
        onSearch,
        spinning,
        onLoadVideoList,
        selectVideo,
        uploadAction,
        handleChange,
        replaceFields,
        headers,
      };
    },
  });
</script>