<template>
  <a-cascader v-model="selectValue" :options="libMap" :fieldNames="fieldNames" @change="selectChange" placeholder="请选择" />
</template>
<script>
import { defineComponent, ref } from "vue";
import libMap from "./libs/lbs.map.json";

export default defineComponent({
  name: "Select",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    returnIDFormat: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "changeAddress"],
  setup(props, context) {
    const selectChange = (value, selectedOptions) => {
      context.emit(
        "update:modelValue",
        props.returnIDFormat == true ? value : selectedOptions
      );
      context.emit("changeAddress", selectedOptions);
    };

    return {
      selectValue: ref(props.modelValue),
      selectChange,
      libMap,
      fieldNames: {
        label: "fullname",
        value: "id",
        children: "children",
      },
    };
  },
});
</script>